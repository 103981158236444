import { RecursivePartial } from 'types';
import { QRCodeTypeEnum } from 'types/qrCodes';
import createCloudinaryMediaDetailsDTO from 'api/media/entities/createCloudinaryMediaDetailsDTO';
import { BusinessPageDTO, MediaDTO, ScheduleDayDTO, ScheduleDayDateRangeDTO } from '../types';
import { createLinkDTO } from './createLinkDTO';
import { createColorsDTO } from './createColorsDTO';
import { createButtonDTO } from './createButtonDTO';
import { createAddressDTO } from './createAddressDTO';
import { createContactsDTO } from './createContactsDTO';

const createDateRangeDTO = (entry?: Partial<ScheduleDayDateRangeDTO>): ScheduleDayDateRangeDTO => {
  return {
    start: entry?.start || '',
    end: entry?.end || '',
  };
};

export const createDefaultDateRangeDTO = (): ScheduleDayDateRangeDTO => {
  return {
    start: new Date(new Date().setHours(8, 0, 0, 0)),
    end: new Date(new Date().setHours(18, 0, 0, 0)),
  };
};

const createScheduleDayDTO = (entry?: Partial<ScheduleDayDTO>): ScheduleDayDTO => {
  return {
    isActive: entry?.isActive ?? true,
    dateRanges: (entry?.dateRanges || [null]).map((dateRange) =>
      dateRange ? createDateRangeDTO(dateRange) : createDefaultDateRangeDTO()
    ),
  };
};

export const createBusinessPageDTO = (
  entry?: RecursivePartial<Omit<BusinessPageDTO, 'schedule'>> & { schedule?: Partial<BusinessPageDTO['schedule']> },
  medias?: MediaDTO[]
): BusinessPageDTO => {
  return {
    type: QRCodeTypeEnum.BUSINESS_PAGE,
    name: entry?.name || '',
    colors: createColorsDTO(entry?.colors),
    image: createCloudinaryMediaDetailsDTO(entry?.image, medias),
    company: {
      name: entry?.company?.name || '',
      tagline: entry?.company?.tagline || '',
      summary: entry?.company?.summary || '',
    },
    buttons: (entry?.buttons || []).map((button) => createButtonDTO(button)),
    schedule: {
      isAm: entry?.schedule?.isAm ?? true,
      monday: createScheduleDayDTO(entry?.schedule?.monday),
      tuesday: createScheduleDayDTO(entry?.schedule?.tuesday),
      wednesday: createScheduleDayDTO(entry?.schedule?.wednesday),
      thursday: createScheduleDayDTO(entry?.schedule?.thursday),
      friday: createScheduleDayDTO(entry?.schedule?.friday),
      saturday: createScheduleDayDTO({ isActive: false, ...(entry?.schedule?.saturday || {}) }),
      sunday: createScheduleDayDTO({ isActive: false, ...(entry?.schedule?.sunday || {}) }),
    },
    address: createAddressDTO(entry?.address),
    facilities: entry?.facilities && Array.isArray(entry.facilities) ? (entry.facilities as string[]) : [],
    contactDetails: {
      name: entry?.contactDetails?.name || '',
      surname: entry?.contactDetails?.surname || '',
    },
    contacts: createContactsDTO(entry?.contacts),
    summary: entry?.summary || '',
    links: (entry?.links || []).map((linkEntry) => createLinkDTO(linkEntry)),
    loaderImage: createCloudinaryMediaDetailsDTO(entry?.loaderImage, medias),
  };
};

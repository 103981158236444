import { useEffect } from 'react';
import { deleteCookie, getCookie } from '../utils/common';
import { REFRESH_PAGE_COOKIE } from '../constants/constants';

// Use this in every page that should be auto-refreshed when backend requires this by sending 'refresh-page' cookie
export const useRefreshPage = () => {
  useEffect(() => {
    const handleStorage = (e: StorageEvent) => {
      if (e.key === REFRESH_PAGE_COOKIE && e.newValue) {
        window.location.reload();
      }
    };
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);
};

// This is a checker for whenever there is a 'refresh-page' cookie.
// It sets the localStorage variable, and all consumers (pages with useRefreshPage in the same browser) will do a page reaload
export const checkRefreshPageCookie = () => {
  const refreshPage = getCookie(REFRESH_PAGE_COOKIE);
  if (refreshPage) {
    deleteCookie(REFRESH_PAGE_COOKIE);
    // Send an event to all consumers that we need to do a refresh
    localStorage.setItem(REFRESH_PAGE_COOKIE, refreshPage);
  }
};

/* eslint-disable class-methods-use-this */
import { ROUTES } from 'constants/api.routes';
import { I18N_LANGUAGE_TO_STRIPE_LOCALE } from 'constants/languages';

import {
  StatisticsChartsRequestParam,
  StatisticsQRFilterResponse,
  StatisticFilterOption,
  BatchAnalyticsResponse,
} from 'types/statistics';
import { ReportTypeEnum } from 'types';
import { axiosApiInstance } from '../axios';

class StatisticsApi {
  public async getStatisticsBatch(params: StatisticsChartsRequestParam): Promise<BatchAnalyticsResponse> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { data } = await axiosApiInstance.get(ROUTES.STATISTICS.BATCH, {
      params: {
        ...params,
        timezone,
      },
    });

    return data;
  }

  public async getStatisticsQrFilter(): Promise<StatisticsQRFilterResponse[]> {
    const { data } = await axiosApiInstance.get(ROUTES.STATISTICS.FILTERS_QR_CODE);

    return data;
  }

  public async getStatisticsCountryFilter(params: {
    language: keyof typeof I18N_LANGUAGE_TO_STRIPE_LOCALE;
  }): Promise<StatisticFilterOption[]> {
    const { data } = await axiosApiInstance.get(ROUTES.STATISTICS.FILTERS_COUNTRIES, { params });

    return data;
  }

  public async getStatisticsCitiesFilter(countries: string[]): Promise<string[]> {
    const { data } = await axiosApiInstance.get(ROUTES.STATISTICS.FILTERS_CITIES, {
      params: {
        countries,
      },
    });

    return data;
  }

  public async getStatisticsOSFilter(): Promise<string[]> {
    const { data } = await axiosApiInstance.get(ROUTES.STATISTICS.FILTERS_OS);

    return data;
  }

  public async getStatisticsExport(
    params: StatisticsChartsRequestParam & { fileType: ReportTypeEnum }
  ): Promise<string> {
    const { data } = await axiosApiInstance.get(ROUTES.STATISTICS.EXPORT, { params, responseType: 'blob' });

    return data;
  }
}

export const statisticsApi = new StatisticsApi();

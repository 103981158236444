import { User } from 'types/auth';

export type SubscriptionProductPrice = {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  currency: string;
  amount: number;
  trialAmount: number;
  trialDays: number;
  stripeId: string;
  paypalId: string;
  productId: string;
  duration: number;
  durationUnits: string;
};

export type CreateSubscriptionRequestParams = {
  priceId: string;
  subscriptionId?: string;
  paymentMethodId?: string;
  confirmRemovalPendingPaypalSubscription?: boolean;
};

export type CreatePayPalSubscriptionResponse = {
  status: string;
  approveLink: string;
};

export type CreateStripeSubscriptionResponse = {
  status: string;
  clientSecret: string;
  user: User;
};

export enum StripeSubscriptionResponseStatusEnum {
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  UNPAID = 'unpaid',
  CANCELED = 'canceled',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  TRIALING = 'trialing',
}

export enum DURATION_CONTEXT {
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months',
  YEARS = 'years',
}

export type SubscriptionProductVariables = {
  currency: string;
  currencySymbol: string;
  titlePrice: string;
  trialPrice: number;
  trialDuration: number;
  trialContext: `${DURATION_CONTEXT}`;
  regularPrice: number;
  regularDuration: number;
  regularContext: `${DURATION_CONTEXT}`;
};

export type SubscriptionProduct = {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  description: string;
  priceTitle: string;
  sku: string;
  isFavourite: boolean;
  price: SubscriptionProductPrice;
  priceNumber: number;
  priceDecimal: number;
  label: string;
  productVariables: SubscriptionProductVariables;
  applePayDisabled?: boolean;
  googlePayDisabled?: boolean;
};

export type Currency = {
  code: string;
  symbol: string;
  isDefault: boolean;
};

export type Subscription = {
  id: string;
  createdAt: string;
  updatedAt: string;
  provider: string;
  providerSubscriptionId: string;
  status: SubscriptionStatusEnum;
  expiresAt: string;
  priceId: string;
  userId: string;
  unpaidDaysLeft?: number;
  product: SubscriptionProduct;
};

export enum SubscriptionStatusEnum {
  ACTIVE = 'active',
  EXPIRED = 'expired',
  CANCELLED = 'cancelled',
  INCOMPLETE = 'incomplete',
  INITIAL = 'initial',
}

export enum UserStatusEnum {
  FREEMIUM_NEW = 'freemium_new',
  FREEMIUM_OLD = 'freemium_old',
  PREMIUM = 'premium',
  PREMIUM_GIFTED = 'premium_gifted',
  PREMIUM_CANCELLING = 'premium_cancelling',
  PREMIUM_CANCELLED = 'premium_cancelled',
  PREMIUM_UNPAID = 'premium_unpaid',
  PREMIUM_IN_DEBT = 'premium_in_debt',
  DEACTIVATED = 'deactivated',
}

export enum UserMarketingFlowStatusEnum {
  ABANDONED = 'abandoned', // User was sent an abandoned cart email, this triggers the flow
  LOGGEDIN = 'loggedin', // User was not sent an abandoned cart email, and was displayed the download popup
  LOGGEDIN_PLANS = 'loggedin_plans', // User was not sent an abandoned cart email, and visited the plans page
  ABANDONED_LOGGEDIN = 'abandoned_loggedin', // User was sent an abandoned cart email, and was displayed the download popup
  ABANDONED_TIMEOUT = 'abandoned_timout', // User abandoned email was triggered by timeout
  DOWNLOADED = 'downloaded',
  SENT_SECOND_EMAIL = 'sent_second_email', // Scan & Track or Don't forget to download
  SENT_PRINT_EMAIL = 'sent_print_email',
  FINISHED = 'finished',
}

export enum FlowTypeEnum {
  SOME_DAYS_FREE = 'some-days-free',
  UNLOCK = 'unlock',
}

export const PaidUserStatuses = [UserStatusEnum.PREMIUM, UserStatusEnum.PREMIUM_GIFTED, UserStatusEnum.PREMIUM_UNPAID];

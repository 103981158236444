/* eslint-disable consistent-return */
/* eslint-disable no-console */
import { AnyAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import { showNotification } from 'config/toast';
import { getValidErrorMessage } from 'utils';
import { retry } from 'utils/retry';
import { userSettingsApi } from 'api/userSettings';
import { DEFAULT_LOCALE } from 'constants/languages';
import { setAuthCookie } from 'utils/api';
import { getCookie, itIsUnlockFlow } from '../../utils/common';
import { LoginData, SocialSubmitPayload, User, UserCredential } from '../../types/auth';
import { authApi } from '../../api/auth';
import { localStorageService } from '../../libs/localStorage/local.storage.service';
import { setUser, removeUser, updateLangAndRegion } from './index';
import { axiosApiInstance } from '../../api/axios';
import i18n, { changeLanguage } from 'config/i18n';

const signUpDatas = () => {
  const language = getCookie('lang') || i18n.language || navigator.language.split('-')[0] || navigator.language;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const isUnlockFlow = itIsUnlockFlow();
  return {
    language,
    timezone,
    isUnlockFlow,
  };
};
const finishSignUp = async (
  data: LoginData,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  onUserSet?: () => void
) => {
  const { language, timezone } = signUpDatas();
  dispatch(setUser(data.user));
  onUserSet?.();
  setAuthCookie(data.accessToken, data.user.role);
  dispatch(updateLangAndRegion({ language, timezone }));
};

export const signUp = createAsyncThunk(
  'auth/signUp',
  async ({ credentials, onUserSet }: { credentials: UserCredential; onUserSet?: () => void }, { dispatch }) => {
    const { language, timezone, isUnlockFlow } = signUpDatas();
    credentials.isUnlockFlow = isUnlockFlow;
    credentials.timezone = timezone;
    credentials.language = language;
    try {
      const { data } = await authApi.signUp(credentials);
      await finishSignUp(data, dispatch, onUserSet);
    } catch (error) {
      showNotification(getValidErrorMessage(error), 'error', () => {}, 'sign-in-error');
    }
  }
);

export const signUpSocial = createAsyncThunk(
  'auth/signUpSocial',
  async ({ type, token, signup, onUserSet }: SocialSubmitPayload & { onUserSet?: () => void }, { dispatch }) => {
    const signupDetails: SocialSubmitPayload = { token, type, ...signUpDatas() };
    let data = null;
    try {
      if (type === 'google') {
        const response = await authApi.googleSignUp(signupDetails);
        data = response.data;
      } else if (type === 'facebook') {
        const response = await authApi.facebooSignUp(signupDetails);
        data = response.data;
      } else if (type === 'apple') {
        if (signup) {
          signupDetails.code = signupDetails.token;
          delete signupDetails.token;
          const response = await authApi.appleSignup(signupDetails);
          data = response.data;
        } else {
          const response = await authApi.appleSignupFinalize(signupDetails);
          data = response.data;
        }
      }
      if (data) await finishSignUp(data, dispatch, onUserSet);
    } catch (error) {
      console.error(error);
      showNotification(getValidErrorMessage(error), 'error', () => {}, 'sign-in-social-error');
    }

    return null;
  }
);

export const updateUser = createAsyncThunk(
  'auth/updateUser',
  // eslint-disable-next-line
  async (callback: (() => void) | undefined = undefined, { dispatch }) => {
    try {
      const user = await authApi.getUser();
      callback?.();
      dispatch(setUser(user));

      if (user) {
        document.cookie = `lang=${user.language ?? DEFAULT_LOCALE}; domain=${window.location.hostname}; path=/`;
      }
      return user;
    } catch (error) {
      console.error(error);
      showNotification(getValidErrorMessage(error), 'error');
      return error;
    }
  }
);

export const logout = createAsyncThunk('auth/logout', async (_, { dispatch }) => {
  try {
    await retry(authApi.logout);

    dispatch(removeUser());
    setAuthCookie('', '');
  } catch (error) {
    console.error(error);
    return error;
  } finally {
    // Remove local data
    dispatch(removeUser());
    setAuthCookie('', '');
  }
});

export const refreshToken = createAsyncThunk('auth/refresh', async (ignoreErrors: boolean, { dispatch }) => {
  try {
    const { data } = await authApi.refreshToken({
      ...localStorageService.getTokens(),
      user: localStorageService.getUser() as User,
    });
    axiosApiInstance.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
    dispatch(setUser(data.user));

    setAuthCookie(data.accessToken, data.user.role);
    await changeLanguage(data.user.language);

    return data;
  } catch (error) {
    // logout
    setAuthCookie('', '');
    dispatch(removeUser());
    if (!ignoreErrors) {
      showNotification(getValidErrorMessage(error), 'error');
      console.error(error);
    }
  }
});

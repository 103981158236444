import {
  MediaProviders,
  CloudinaryProviderMediaDetailsDTO,
  NoProviderMediaDetailsDTO,
  R2ProviderMediaDetailsDTO,
} from './types';

type Entry = { provider?: string };

export const isCloudinaryProviderMediaDetailsDTO = (
  entry?: Entry | null
): entry is CloudinaryProviderMediaDetailsDTO => {
  if (!entry) {
    return false;
  }
  return entry.provider === MediaProviders.CLOUDINARY;
};

export const isR2ProviderMediaDetailsDTO = (entry?: Entry | null): entry is R2ProviderMediaDetailsDTO => {
  if (!entry) {
    return false;
  }
  return entry.provider === MediaProviders.R2;
};

export const isNoProviderMediaDetailsDTO = (entry?: Entry | null): entry is NoProviderMediaDetailsDTO => {
  if (!entry) {
    return false;
  }
  return entry.provider === MediaProviders.NONE;
};

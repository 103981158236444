/**
 * Transforms object values using provided transformers
 * @param object - object to transform
 * @param transformers - transformers to use
 * @returns transformed object
 * @example
 * const object = { a: 1, b: 2 };
 *
 * const transformedObject = transformObject(
 *  object,
 *  {
 *    a: (value) => value + 1,
 *    b: (value) => value.toString()
 *  }
 * );
 *
 * // transformedObject = { a: 2, b: '2' };
 */

import { IncomingMessage } from 'http';

export const transformObject = <
  T extends Record<string, unknown>,
  Transformers extends { [key in keyof T]?: (value: T[key]) => unknown }
>(
  object: T,
  transformers: Transformers
): { [key in keyof T]: Transformers[key] extends (value: T[key]) => infer U ? U : T[key] } => {
  return Object.entries(object).reduce((acc, [key, value]) => {
    const transform = transformers[key as keyof Transformers];

    if (!transform) {
      acc[key as keyof T] = value;

      return acc;
    }

    acc[key as keyof T] = transform(value as any);

    return acc;
  }, {} as any);
};

export const getCookie = (name: string) => {
  return decodeURIComponent(
    document.cookie
      .split('; ')
      .find((row) => row.startsWith(`${name}=`))
      ?.split('=')[1] || ''
  );
};
export const itIsUnlockFlow = () => {
  const flow = getCookie('isUnlockFlow');
  return flow ? atob(flow) === 'true' : false;
};
export const setCookie = (
  name: string,
  value: string,
  options: { days?: number; path?: string; secure?: boolean; sameSite?: 'Strict' | 'Lax' | 'None' } = {}
) => {
  const { days = 7, path = '/', secure = false, sameSite = 'Lax' } = options;

  const expires = new Date();
  expires.setDate(expires.getDate() + days);

  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires.toUTCString()}; path=${path}; ${
    secure ? 'secure; ' : ''
  }SameSite=${sameSite}`;
};

export const deleteCookie = (name: string, path = '/') => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}`;
};

export const isNewUser = () => {
  const newUser = getCookie('newUser');
  return newUser && newUser === 'true';
};

export const toLowerCaseFirstChar = (str: string) => str && str[0].toLowerCase() + str.slice(1);

export const toLowerCaseFirstThreeChars = (str: string) => str && str.slice(0, 3).toLowerCase() + str.slice(3);

interface PlausibleEvent {
  n: string;
  u: string;
  d: string;
  r?: string;
  [key: string]: unknown;
}

interface PlausibleEventServerSide {
  name: string;
  url: string;
  domain: string;
  referrer: string;
  props?: Record<string, string>;
}

interface PlausibleServerRequest {
  ip?: string;
  userAgent?: string;
  host?: string;
}

export const getForwardedFor = (req: IncomingMessage) => {
  const extractIPv4 = (ip = '') => {
    const ipv6Mapped = ip.match(/::ffff:(\d+\.\d+\.\d+\.\d+)/);
    return ipv6Mapped ? ipv6Mapped[1] : ip;
  };

  if (req.headers['cf-connecting-ip']) {
    return extractIPv4(req.headers['cf-connecting-ip'] as string);
  }

  if (req.headers['x-real-ip']) {
    return extractIPv4(req.headers['x-real-ip'] as string);
  }

  return extractIPv4(req.socket.remoteAddress);
};

export const sendPlausibleServerEvent = async (path: string, request: PlausibleServerRequest) => {
  const dataDomain = process.env.PLAUSIBLE_TEST_LOCAL ? 'myqrcode.loca.lt' : 'myqrcode.com';

  const event = {
    n: 'pageview',
    u: `https://${request.host || dataDomain}${path}`,
    d: dataDomain,
    r: `https://${request.host || dataDomain}${path}`,
  };
  sendPlausibleReadyEvent(event, request);
};

export const sendPlausibleReadyEvent = async (event: PlausibleEvent, request: PlausibleServerRequest) => {
  const eventServerSide: PlausibleEventServerSide = {
    name: event.n,
    url: event.u,
    domain: event.d,
    referrer: event.r || '',
  };

  const response = await fetch(`https://plausible.io/api/event`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Forwarded-For': request.ip || '',
      'User-Agent': request.userAgent || '',
    }),
    body: JSON.stringify(eventServerSide),
  });

  const body = await response.text();

  return body;
};

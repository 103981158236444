/* eslint-disable class-methods-use-this */
import { ROUTES } from 'constants/api.routes';

import { AccountSettings, GeneralInfo, CompanyInfo, Language } from 'types/account';
import { DefaultApiResponse } from 'types';
import { axiosApiInstance } from '../axios';
import { I18N_LANGUAGE_TO_STRIPE_LOCALE } from 'constants/languages';
import { User } from '../../types/auth';
import { UserMarketingFlowStatusEnum } from '../../types/subscriptions';
import { setUserMarketingStatus } from 'store/auth';
import { Dispatch } from 'redux';

class UserSettingsApi {
  public async getAbandonedCart(): Promise<void> {
    await axiosApiInstance.get(ROUTES.USER_SETTINGS.ABANDONED_CART);
  }

  public async cancelAbandonedCart(): Promise<void> {
    await axiosApiInstance.get(ROUTES.USER_SETTINGS.ABANDONED_CART_CANCEL);
  }

  public async setFirstLogin(
    user: User,
    status?: UserMarketingFlowStatusEnum,
    dispatch?: Dispatch
  ): Promise<UserMarketingFlowStatusEnum | undefined> {
    const sessionStorageStatus = sessionStorage.getItem('setFirstLogin');
    if (sessionStorageStatus) {
      const { user: sessionUser, status: sessionStatus } = JSON.parse(sessionStorageStatus);
      if (sessionUser.id === user.id && sessionStatus === status) {
        return sessionStatus;
      }
    }
    sessionStorage.setItem('setFirstLogin', JSON.stringify({ user, status }));
    const { data } = await axiosApiInstance.put(ROUTES.USER_SETTINGS.FIRST_LOGIN_MARKETING, { user, status });
    if (data.marketingFlowState) {
      dispatch?.(setUserMarketingStatus(data.marketingFlowState));
    }
    sessionStorage.removeItem('setFirstLogin');
    return data.marketingFlowState;
  }

  public async getSubscriptionProduct(): Promise<AccountSettings> {
    const { data } = await axiosApiInstance.get(ROUTES.USER_SETTINGS.GET_SETTINGS_INFO);

    return data;
  }

  public async updateGeneralInfo(payload: GeneralInfo): Promise<DefaultApiResponse> {
    const { data } = await axiosApiInstance.put(ROUTES.USER_SETTINGS.GENERAL_INFO, payload);

    return data;
  }

  public async updateCompanyInfo(payload: CompanyInfo): Promise<DefaultApiResponse> {
    const { data } = await axiosApiInstance.put(ROUTES.USER_SETTINGS.COMPANY_INFO, payload);

    return data;
  }

  public async changePassword(password: string): Promise<DefaultApiResponse> {
    const { data } = await axiosApiInstance.put(ROUTES.USER_SETTINGS.CHANGE_PASSWORD, { password });

    return data;
  }

  public async changeEmail(email: string): Promise<DefaultApiResponse> {
    const { data } = await axiosApiInstance.put(ROUTES.USER_SETTINGS.CHANGE_EMAIL, { email });

    return data;
  }

  public async changeTimeZone(timezone: string): Promise<DefaultApiResponse> {
    const { data } = await axiosApiInstance.put(ROUTES.USER_SETTINGS.CHANGE_TIMEZONE, { timezone });

    return data;
  }

  public async getTimeZones(params: {
    language: keyof typeof I18N_LANGUAGE_TO_STRIPE_LOCALE;
  }): Promise<{ name: string; displayName: string }[]> {
    const { data } = await axiosApiInstance.get(ROUTES.USER_SETTINGS.GET_TIMEZONES, { params });

    return data;
  }

  public async changeLanguage(languageCode?: string): Promise<DefaultApiResponse> {
    if (!languageCode) throw new Error('languageCode not given');

    const { data } = await axiosApiInstance.put(ROUTES.USER_SETTINGS.CHANGE_LANGUAGE, { languageCode });

    return data;
  }

  public async getLanguages(params: { language: keyof typeof I18N_LANGUAGE_TO_STRIPE_LOCALE }): Promise<Language[]> {
    const { data } = await axiosApiInstance.get(ROUTES.USER_SETTINGS.GET_LANGUAGES, { params });

    return data;
  }

  public async getNativeLanguages(): Promise<Language[]> {
    const { data } = await axiosApiInstance.get(ROUTES.USER_SETTINGS.GET_NATIVE_LANGUAGES);
    return data;
  }

  public async getCountries(language: string): Promise<Language[]> {
    const { data } = await axiosApiInstance.get(ROUTES.USER_SETTINGS.GET_COUNTRIES, { params: { language } });
    return data;
  }

  public async bannerClicked(): Promise<true> {
    const { data } = await axiosApiInstance.post(ROUTES.USER_SETTINGS.BANNER_CLICKED);

    return data;
  }
}

export const userSettingsApi = new UserSettingsApi();

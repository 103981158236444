import { RecursivePartial } from 'types';
import { MediaProviders, R2ProviderMediaDetailsDTO } from '../types';

const createR2ProviderMediaDetailsDTO = (
  entry?: RecursivePartial<R2ProviderMediaDetailsDTO> | null
): R2ProviderMediaDetailsDTO => {
  return {
    provider: MediaProviders.R2,
    publicId: entry?.publicId || '',
    bucketRootUrl: entry?.bucketRootUrl || '',
    resourceType: entry?.resourceType || '',
    format: entry?.format || '',
    bytes: entry?.bytes || 0,
  };
};

export default createR2ProviderMediaDetailsDTO;

import { TransformationStatusEnum } from 'api/qrCode/types';

export enum MediaProviders {
  CLOUDINARY = 'cloudinary',
  R2 = 'r2',
  NONE = 'none',
}

export type CloudinaryMediaDetailsDTO = {
  publicId: string;
  key?: string;
  resourceType: string;
  format: string;
  bytes: number;
  storageProvider?: string;
  hasAutoTransformation?: TransformationStatusEnum;
  bucketRootUrl?: string;
};

export interface MediaWithProviderBaseDTO {
  provider: `${MediaProviders}`;
}

export interface NoProviderMediaDetailsDTO extends MediaWithProviderBaseDTO {
  provider: MediaProviders.NONE;
  src: string;
}

export interface CloudinaryProviderMediaDetailsDTO extends MediaWithProviderBaseDTO {
  provider: MediaProviders.CLOUDINARY;
  bucketRootUrl?: string;
  publicId: string;
  resourceType: string;
  format: string;
  bytes: number;
}

export interface R2ProviderMediaDetailsDTO extends MediaWithProviderBaseDTO {
  provider: MediaProviders.R2;
  publicId: string;
  bucketRootUrl: string;
  resourceType: string;
  format: string;
  bytes: number;
}

export type MediaWithProviderDetailsDTO =
  | CloudinaryProviderMediaDetailsDTO
  | R2ProviderMediaDetailsDTO
  | NoProviderMediaDetailsDTO;

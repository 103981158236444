import { QRCodeDTO } from 'api/qrCode/types';
import React from 'react';

export enum QRCodeTypeEnum {
  V_CARD = 'vCard',
  URL = 'url',
  PLAIN_TEXT = 'plainText',
  BUSINESS_PAGE = 'businessPage',
  APP = 'app',
  VIDEO = 'video',
  MENU = 'menu',
  PDF = 'pdf',
  SOCIAL_MEDIA = 'socialMedia',
  FACEBOOK = 'facebook',
  WIFI = 'wifi',
  IMAGES = 'images',
}

export enum QRCodeUrlEnum {
  V_CARD = 'vcard',
  URL = 'url',
  PLAIN_TEXT = 'plain-text',
  BUSINESS_PAGE = 'business-page',
  APP = 'app',
  VIDEO = 'video',
  MENU = 'menu',
  PDF = 'pdf',
  SOCIAL_MEDIA = 'social-media',
  FACEBOOK = 'facebook',
  WIFI = 'wifi',
  IMAGES = 'images',
}

export const QRCodeUrlToTypeMapping = {
  [QRCodeUrlEnum.V_CARD]: QRCodeTypeEnum.V_CARD,
  [QRCodeUrlEnum.URL]: QRCodeTypeEnum.URL,
  [QRCodeUrlEnum.PLAIN_TEXT]: QRCodeTypeEnum.PLAIN_TEXT,
  [QRCodeUrlEnum.BUSINESS_PAGE]: QRCodeTypeEnum.BUSINESS_PAGE,
  [QRCodeUrlEnum.APP]: QRCodeTypeEnum.APP,
  [QRCodeUrlEnum.VIDEO]: QRCodeTypeEnum.VIDEO,
  [QRCodeUrlEnum.MENU]: QRCodeTypeEnum.MENU,
  [QRCodeUrlEnum.PDF]: QRCodeTypeEnum.PDF,
  [QRCodeUrlEnum.SOCIAL_MEDIA]: QRCodeTypeEnum.SOCIAL_MEDIA,
  [QRCodeUrlEnum.FACEBOOK]: QRCodeTypeEnum.FACEBOOK,
  [QRCodeUrlEnum.WIFI]: QRCodeTypeEnum.WIFI,
  [QRCodeUrlEnum.IMAGES]: QRCodeTypeEnum.IMAGES,
};

export const QRCodeTypeToUrlMapping = {
  [QRCodeTypeEnum.V_CARD]: QRCodeUrlEnum.V_CARD,
  [QRCodeTypeEnum.URL]: QRCodeUrlEnum.URL,
  [QRCodeTypeEnum.PLAIN_TEXT]: QRCodeUrlEnum.PLAIN_TEXT,
  [QRCodeTypeEnum.BUSINESS_PAGE]: QRCodeUrlEnum.BUSINESS_PAGE,
  [QRCodeTypeEnum.APP]: QRCodeUrlEnum.APP,
  [QRCodeTypeEnum.VIDEO]: QRCodeUrlEnum.VIDEO,
  [QRCodeTypeEnum.MENU]: QRCodeUrlEnum.MENU,
  [QRCodeTypeEnum.PDF]: QRCodeUrlEnum.PDF,
  [QRCodeTypeEnum.SOCIAL_MEDIA]: QRCodeUrlEnum.SOCIAL_MEDIA,
  [QRCodeTypeEnum.FACEBOOK]: QRCodeUrlEnum.FACEBOOK,
  [QRCodeTypeEnum.WIFI]: QRCodeUrlEnum.WIFI,
  [QRCodeTypeEnum.IMAGES]: QRCodeUrlEnum.IMAGES,
};

export const QRCodeTypeLabels: { [key in QRCodeTypeEnum]: string } = {
  [QRCodeTypeEnum.V_CARD]: 'VCard',
  [QRCodeTypeEnum.URL]: 'Website URL',
  [QRCodeTypeEnum.PLAIN_TEXT]: 'Simple Text',
  [QRCodeTypeEnum.BUSINESS_PAGE]: 'Business',
  [QRCodeTypeEnum.APP]: 'App',
  [QRCodeTypeEnum.VIDEO]: 'Video',
  [QRCodeTypeEnum.MENU]: 'Menu',
  [QRCodeTypeEnum.PDF]: 'PDF',
  [QRCodeTypeEnum.SOCIAL_MEDIA]: 'Social Media',
  [QRCodeTypeEnum.FACEBOOK]: 'Facebook',
  [QRCodeTypeEnum.WIFI]: 'Wi-Fi',
  [QRCodeTypeEnum.IMAGES]: 'Image',
};

export type QRCodesTableSortFields = keyof Pick<QRCodeDTO, 'name' | 'scansAmount' | 'createdAt'>;

export interface TileData {
  type: QRCodeTypeEnum;
  id: number;
  v2Icon?: string;
  v2IconSelected?: string;
  tileIcon: string;
  tileIconSelected: string;
  tileTitle: string;
  previewImage: string;
  tileDescription: string;
  textKey: string;
  descriptionKey: string;
  link: string;
}

export interface TileDataWithIndex extends TileData {
  index: number;
}

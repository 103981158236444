import { RecursivePartial } from 'types';
import { QRCodeTypeEnum } from 'types/qrCodes';
import { TextDTO } from '../types';
import { removeExtraWhiteSpace } from '../../../utils/text';

export const createTextDTO = (entry?: RecursivePartial<TextDTO>): TextDTO => {
  return {
    type: QRCodeTypeEnum.PLAIN_TEXT,
    name: entry?.name || '',
    // Remove all types of whitespace and replace with a single space
    text: removeExtraWhiteSpace(entry?.text || ''),
  };
};

import { RecursivePartial } from 'types';
import { QRCodeTypeEnum } from 'types/qrCodes';
import createCloudinaryMediaDetailsDTO from 'api/media/entities/createCloudinaryMediaDetailsDTO';
import { createButtonDTO } from './createButtonDTO';
import { ImageGalleryDTO } from '../types/ImageGalleryDTO';
import { MediaDTO } from '../types';

type Entry = RecursivePartial<Omit<ImageGalleryDTO, 'gallery'>> & Partial<Pick<ImageGalleryDTO, 'gallery'>>;

export const createImageGalleryDTO = (entry?: Entry, medias?: MediaDTO[]): ImageGalleryDTO => {
  return {
    type: QRCodeTypeEnum.IMAGES,
    name: entry?.name || '',
    colors: {
      primary: entry?.colors?.primary || '',
      secondary: entry?.colors?.secondary || '',
    },
    gallery: entry?.gallery || [],
    info: {
      headline: entry?.info?.headline || '',
      website: entry?.info?.website || '',
      description: entry?.info?.description || '',
    },
    buttons: (entry?.buttons || []).map(createButtonDTO),
    isShareable: entry?.isShareable ?? true,
    loaderImage: createCloudinaryMediaDetailsDTO(entry?.loaderImage, medias),
  };
};

import { RecursivePartial } from 'types';
import { QRCodeTypeEnum } from 'types/qrCodes';
import createCloudinaryMediaDetailsDTO from 'api/media/entities/createCloudinaryMediaDetailsDTO';
import { createButtonDTO } from './createButtonDTO';
import { FacebookDTO } from '../types/FacebookDTO';
import { MediaDTO } from '../types';

type Entry = RecursivePartial<Omit<FacebookDTO, 'gallery'>> & Partial<Pick<FacebookDTO, 'gallery'>>;

export const createFacebookDTO = (entry?: Entry, medias?: MediaDTO[]): FacebookDTO => {
  return {
    type: QRCodeTypeEnum.FACEBOOK,
    name: entry?.name || '',
    colors: {
      primary: entry?.colors?.primary || '',
      secondary: entry?.colors?.secondary || '',
    },
    gallery: entry?.gallery || [],
    info: {
      url: entry?.info?.url || '',
      name: entry?.info?.name || '',
      title: entry?.info?.title || '',
      website: entry?.info?.website || '',
    },
    buttons: (entry?.buttons || []).map(createButtonDTO),
    loaderImage: createCloudinaryMediaDetailsDTO(entry?.loaderImage, medias),
  };
};

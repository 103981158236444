/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { AuthState } from './index';
import { User } from '../../types/auth';
import { FlowTypeEnum } from '../../types/subscriptions';
import { LINKS } from '../../constants/constants';

export const selectAuth = (state: RootState) => state.auth;

export const selectIsAuthInProgress = createSelector([selectAuth], (auth: AuthState) => auth.isAuthInProgress);

export const selectUser = createSelector([selectAuth], (auth: AuthState) => auth.user);

export const selectUserStatus = createSelector([selectAuth, selectUser], (_, user) => (user as User)?.status);
export const selectUserFlow = createSelector([selectAuth, selectUser], (_, user) => (user as User)?.flow);
export const selectProductPage = createSelector([selectAuth, selectUser], (_, user) =>
  (user as User)?.flow === FlowTypeEnum.UNLOCK ? LINKS.UPGRADE : LINKS.SUBSCRIPTION_PLANS
);
export const selectUserTrialDays = createSelector(
  [selectAuth, selectUser],
  (_, user) => (user as User)?.flowData?.freeDaysQuota
);
export const selectUserMarketingStatus = createSelector(
  [selectAuth, selectUser],
  (_, user) => (user as User)?.marketingFlowState
);
export const selectUserRegistered = createSelector([selectAuth, selectUser], (_, user) => (user as User)?.createdAt);
export const selectUserFreeScans = createSelector([selectAuth, selectUser], (_, user) => (user as User)?.freeScans);

import { RecursivePartial } from 'types';
import { QRCodeTypeEnum } from 'types/qrCodes';
import createCloudinaryMediaDetailsDTO from 'api/media/entities/createCloudinaryMediaDetailsDTO';
import { createButtonDTO } from './createButtonDTO';
import { VideoDTO, PlatformVideoDTO } from '../types/VideoDTO';
import { createColorsDTO } from './createColorsDTO';
import { MediaDTO } from '../types';

export const createPlatformVideoDTO = (
  entry?: RecursivePartial<PlatformVideoDTO>,
  medias?: MediaDTO[]
): PlatformVideoDTO => {
  return {
    name: entry?.name || '',
    description: entry?.description || '',
    source: {
      url: entry?.source?.url || null,
      uploaded: entry?.source?.uploaded ? createCloudinaryMediaDetailsDTO(entry?.source?.uploaded, medias) : null,
    },
  };
};

export const createVideoDTO = (entry?: RecursivePartial<VideoDTO>, medias?: MediaDTO[]): VideoDTO => {
  return {
    type: QRCodeTypeEnum.VIDEO,
    name: entry?.name || '',
    loaderImage: createCloudinaryMediaDetailsDTO(entry?.loaderImage, medias),
    colors: createColorsDTO(entry?.colors),
    info: {
      title: entry?.info?.title || '',
      description: entry?.info?.description || '',
      buttons: (entry?.info?.buttons || []).map((button) => createButtonDTO(button)),
    },
    videos: (entry?.videos || []).map((video) => createPlatformVideoDTO(video, medias)),
    isShareable: entry?.isShareable ?? true,
  };
};

import axios, { AxiosResponse } from 'axios';
import { axiosApiInstance } from 'api/axios';
import { join, normalize, renameFile } from 'utils';
import { ROUTES } from 'constants/api.routes';
import { DefaultApiResponse } from 'types';
import { CloudinaryMediaDetailsDTO } from '../media/types';
import { R2FileDataDto, R2PresignedUrlDto, R2UploadResultDto } from './types';
import { IsAppliedTransformation, TransformationStatusEnum } from 'api/qrCode/types';

export class R2Api {
  public buildResourceURL = (entry: CloudinaryMediaDetailsDTO): string => {
    if (IsAppliedTransformation(entry.hasAutoTransformation))
      return `${entry.bucketRootUrl}/${entry.key ?? entry.publicId}/auto`;
    return `${entry.bucketRootUrl}/${entry.key ?? entry.publicId}`;
  };

  public buildResourceURLForPlayer = (entry: CloudinaryMediaDetailsDTO): string => {
    return `${this.buildResourceURL(entry)}`;
  };

  public async uploadFile(file: File, onProgress?: (progress: number) => void): Promise<R2UploadResultDto> {
    // Trigger initial progress
    onProgress && onProgress(1);
    // Get the presigned URL
    const { data: r2UploadConfig }: AxiosResponse<R2PresignedUrlDto> = await axiosApiInstance.get(
      `${ROUTES.MEDIA.R2.UPLOAD_CONFIG}?timestamp=${Date.now()}&fileName=${file.name}`
    );
    if (!r2UploadConfig) throw new Error('No R2 upload config.');
    const { presignedUrl, id, key } = r2UploadConfig;
    // Prepare the file
    const renamedFile = this.renameFile(file, normalize(file.name || ''));
    // Upload the file
    const config = {
      onUploadProgress: (progressEvent: any) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress && onProgress(percentCompleted);
      },
      headers: {
        'Content-Type': file.type,
      },
    };
    try {
      await axios.put(presignedUrl, renamedFile, config);
    } catch (error) {
      console.error('Error uploading file to R2:', error);
      throw new Error('Failed to upload file to R2');
    }
    // Report the upload
    const reportResponse: AxiosResponse<R2FileDataDto> = await axiosApiInstance.post(ROUTES.MEDIA.R2.REPORT_UPLOAD, {
      publicId: key,
      resourceType: file.type,
      externalId: id,
    });
    return {
      publicId: reportResponse?.data?.key,
      resourceType: file.type,
      format: reportResponse?.data?.format,
      bytes: reportResponse?.data?.bytes,
      storageProvider: reportResponse?.data?.storageProvider,
      bucketRootUrl: reportResponse?.data?.bucketRootUrl,
      key: reportResponse?.data?.key,
      hasAutoTransformation: TransformationStatusEnum.NOT_YET_APPLIED,
    };
  }

  public async uploadFileDirect(file: File, onProgress?: (progress: number) => void): Promise<R2UploadResultDto> {
    onProgress && onProgress(1);

    const formData = new FormData();
    formData.append('file', renameFile(file, normalize(file.name || '')));

    const config = {
      onUploadProgress(progressEvent: any) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress && onProgress(percentCompleted);
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    try {
      const response: AxiosResponse<Record<string, any>> = await axiosApiInstance.post(
        ROUTES.MEDIA.R2.UPLOAD_CONFIG,
        formData,
        config
      );

      // Filter the response to include only the specified fields
      const filteredData = {
        bytes: response.data.bytes,
        format: response.data.format,
        key: response.data.key,
        url: response.data.url,
        publicId: response.data.publicId,
        resourceType: response.data.resourceType,
        storageProvider: response.data.storageProvider,
        bucketRootUrl: response.data.bucketRootUrl,
        hasAutoTransformation: response.data.hasAutoTransformation,
      };

      return filteredData;
    } catch (error) {
      console.error('Error uploading file to R2:', error);
      throw new Error('Failed to upload file to R2');
    }
  }

  public async removeFile(id: string): Promise<DefaultApiResponse> {
    return axiosApiInstance.delete(join(ROUTES.MEDIA.R2.DELETE, id));
  }

  private renameFile(file: File, newName: string): File {
    return new File([file], newName, { type: file.type });
  }
}

import { AxiosResponse } from 'axios';

import { ROUTES } from 'constants/api.routes';

import { axiosApiInstance } from '../axios';
import { createTemplateCategoryDTO } from './entities';
import { TemplateCategoriesApiResponse, TemplateCategoriesMapType, TemplateCategoryDTO } from './types';

class TemplateCategoriesApi {
  public async getMany(): Promise<TemplateCategoriesApiResponse> {
    try {
      const { data }: AxiosResponse<TemplateCategoryDTO[]> = await axiosApiInstance.get(
        ROUTES.QR_BUILDER.TEMPLATE_OPTIONS
      );
      if (!data) return [];

      return data.map((item) => createTemplateCategoryDTO(item)).filter(Boolean) as TemplateCategoriesApiResponse;
    } catch (err) {
      console.error('TemplateCategoriesApi.getMany err', err);
      return [];
    }
  }

  public async getCategoriesMap(): Promise<TemplateCategoriesMapType> {
    const categories = await this.getMany();

    const categoriesMap: TemplateCategoriesMapType = categories.reduce((acc, current, index) => {
      acc[current.type] = { ...current, index };
      return acc;
    }, {} as TemplateCategoriesMapType);

    return categoriesMap;
  }
}

export const templateCategoriesApi = new TemplateCategoriesApi();

import { RecursivePartial } from 'types';
import { QRCodeTypeEnum } from 'types/qrCodes';
import createCloudinaryMediaDetailsDTO from 'api/media/entities/createCloudinaryMediaDetailsDTO';
import createMediaWithProviderDetailsDTO from 'api/media/entities/createMediaWithProviderDetailsDTO';
import { SocialMediaDTO, SocialMediaLinkDTO } from '../types/SocialMediaDTO';
import { MediaDTO } from '../types';

type Entry = RecursivePartial<Omit<SocialMediaDTO, 'gallery'>> & Partial<Pick<SocialMediaDTO, 'gallery'>>;

export const createSocialMediaLinkDTO = (entry?: RecursivePartial<SocialMediaLinkDTO>): SocialMediaLinkDTO => {
  return {
    name: entry?.name || '',
    url: entry?.url || '',
    description: entry?.description || '',
    logo: createMediaWithProviderDetailsDTO(entry?.logo),
  };
};

export const createSocialMediaDTO = (entry?: Entry, medias?: MediaDTO[]): SocialMediaDTO => {
  return {
    type: QRCodeTypeEnum.SOCIAL_MEDIA,
    name: entry?.name || '',
    colors: {
      primary: entry?.colors?.primary || '',
      secondary: entry?.colors?.secondary || '',
    },
    gallery: entry?.gallery || [],
    info: {
      headline: entry?.info?.headline || '',
      description: entry?.info?.description || '',
    },
    socials: (entry?.socials || []).map(createSocialMediaLinkDTO),
    loaderImage: createCloudinaryMediaDetailsDTO(entry?.loaderImage, medias),
  };
};

import { DefaultApiResponse } from 'types';
import { CloudinaryMediaDetailsDTO } from '../media/types';
import { CloudinaryApi } from '../cloudinary';
import { R2Api } from '../r2';

class FileUploadApi {
  private cloudinaryApi: CloudinaryApi;
  private r2Api: R2Api;

  constructor() {
    this.cloudinaryApi = new CloudinaryApi();
    this.r2Api = new R2Api();
  }

  public async uploadFile(
    file: File,
    storageProvider: string,
    onProgress?: (progress: number) => void
  ): Promise<CloudinaryMediaDetailsDTO> {
    if (storageProvider === 'r2') {
      return this.r2Api.uploadFile(file, onProgress);
    }
    return this.cloudinaryApi.uploadFile(file, onProgress);
  }

  public buildResourceURL(entry: any): string {
    const provider = entry?.storageProvider || entry?.provider;
    if (provider === 'r2') {
      if (entry.url) return entry.url;
      return this.r2Api.buildResourceURL(entry);
    }
    return this.cloudinaryApi.buildResourceURL(entry);
  }

  public buildResourceURLForPlayer(entry: any): string {
    if (entry?.storageProvider === 'r2') {
      return this.r2Api.buildResourceURLForPlayer(entry);
    }
    return this.cloudinaryApi.buildResourceURLForPlayer(entry);
  }

  public async removeFile(id: string, useR2: boolean): Promise<DefaultApiResponse> {
    if (useR2) {
      return this.r2Api.removeFile(id);
    }
    return this.cloudinaryApi.removeFile(id);
  }
}

const fileUploadApi = new FileUploadApi();

export default fileUploadApi;

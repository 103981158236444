import { RecursivePartial } from 'types';
import { MediaWithProviderDetailsDTO } from '../types';
import {
  isCloudinaryProviderMediaDetailsDTO,
  isNoProviderMediaDetailsDTO,
  isR2ProviderMediaDetailsDTO,
} from '../guards';
import createCloudinaryProviderMediaDetailsDTO from './createCloudinaryProviderMediaDetailsDTO';
import createNoProviderMediaDetailsDTO from './createNoProviderMediaDetailsDTO';
import createR2ProviderMediaDetailsDTO from './createR2ProviderMediaDetailsDTO';

const createMediaWithProviderDetailsDTO = (
  entry?: RecursivePartial<MediaWithProviderDetailsDTO> | null
): MediaWithProviderDetailsDTO => {
  if (isCloudinaryProviderMediaDetailsDTO(entry)) {
    return createCloudinaryProviderMediaDetailsDTO(entry);
  }
  if (isR2ProviderMediaDetailsDTO(entry)) {
    return createR2ProviderMediaDetailsDTO(entry);
  }

  if (isNoProviderMediaDetailsDTO(entry)) {
    return createNoProviderMediaDetailsDTO(entry);
  }
  return createNoProviderMediaDetailsDTO();
};

export default createMediaWithProviderDetailsDTO;

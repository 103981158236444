import { RecursivePartial } from 'types';
import { CloudinaryMediaDetailsDTO } from 'api/media/types';
import { QRCodeTypeEnum } from 'types/qrCodes';
import TypeNumber from './TypeNumber';
import { VideoDTO } from './VideoDTO';
import { WiFiDTO } from './WiFiDTO';
import { PdfDTO } from './PdfDTO';
import { ImageGalleryDTO } from './ImageGalleryDTO';
import { MenuDTO } from './menuDTO';
import { FacebookDTO } from './FacebookDTO';
import { SocialMediaDTO } from './SocialMediaDTO';

export interface GradientColorBoundaryDTO {
  offset: number;
  color: string;
}

export interface GradientDTO {
  type: 'linear' | 'radial';
  rotation?: number;
  colorStops: GradientColorBoundaryDTO[];
}

export type FrameType = '1' | '2' | '3' | '4' | '5' | 'default';

export interface FrameDTO {
  type: FrameType;
  text: string;
  color: string;
  textColor: string;
  background: string;
}

export type ByteModeStringEncoding = 'default' | 'UTF-8' | 'SJIS';

export interface QRCodeOptionsDTO {
  frame: FrameDTO;
  width: number;
  height: number;
  type: 'svg';
  data: string;
  image: {
    selected: string | null;
    uploaded: CloudinaryMediaDetailsDTO | null;
  };
  margin: number;
  qrOptions: {
    typeNumber: TypeNumber;
    mode: 'Numeric' | 'Alphanumeric' | 'Byte' | 'Kanji';
    errorCorrectionLevel: 'L' | 'M' | 'Q' | 'H';
    byteModeStringEncoding: ByteModeStringEncoding;
  };
  imageOptions: {
    hideBackgroundDots: boolean;
    imageSize: number;
    margin: number;
  };
  dotsOptions: {
    type?: 'rounded' | 'dots' | 'classy' | 'classy-rounded' | 'square' | 'extra-rounded';
    color?: string;
    gradient?: GradientDTO;
  };
  cornersSquareOptions: {
    color?: string;
    type?: 'dot' | 'square' | 'extra-rounded';
    gradient?: GradientDTO;
  };
  cornersDotOptions: {
    color?: string;
    type?: 'dot' | 'square';
    gradient?: GradientDTO;
  };
  backgroundOptions: {
    color?: string;
    gradient?: GradientDTO;
  };
}

export type LinkDTO = {
  name: string;
  url: string;
};

export type ColorsDTO = {
  primary: string;
  secondary: string;
};

export type AddressDTO = {
  country: string;
  state: string;
  city: string;
  street: string;
  postalCode: string;
};

export type ButtonDTO = {
  title: string;
  url: string;
};

export type ContactsDTO = {
  phoneNumber: string;
  alternativePhoneNumber: string;
  email: string;
  website: string;
};

export interface VCardDTO {
  type: QRCodeTypeEnum.V_CARD;
  name: string;
  colors: ColorsDTO;
  profile: {
    name: string;
    surname: string;
    image: CloudinaryMediaDetailsDTO | null;
  };
  contacts: ContactsDTO;
  company: {
    name: string;
    position: string;
  };
  summary: string;
  address: AddressDTO;
  links: LinkDTO[];
  loaderImage: CloudinaryMediaDetailsDTO | null;
}

export interface WebsiteDTO {
  type: QRCodeTypeEnum.URL;
  name: string;
  url: string;
}

export type AppInfoDto = {
  name: string;
  developer: string;
  logo: CloudinaryMediaDetailsDTO | null;
  description: string;
  buttons: ButtonDTO[];
};

export type AppDto = {
  type: QRCodeTypeEnum.APP;
  colors: ColorsDTO;
  appInfo: AppInfoDto;
  platformLinks: PlatformLinkDto[];
  loaderImage: CloudinaryMediaDetailsDTO | null;
};

export type PlatformLinkDto = {
  name: AppPlatformNameEnum | '';
  url: string;
};

export enum TransformationStatusEnum {
  NOT_YET_APPLIED = 'not_yet_applied',
  TRANSFORMED = 'transformed',
  TRANSFORMED_FFMPEG = 'transformed_ffmpeg',
  NOT_APPLICABLE = 'not_applicable',
  FAILED = 'failed',
}

export const IsAppliedTransformation = (transformationStatus?: TransformationStatusEnum) => {
  return (
    transformationStatus &&
    (transformationStatus === TransformationStatusEnum.TRANSFORMED ||
      transformationStatus === TransformationStatusEnum.TRANSFORMED_FFMPEG)
  );
};

export enum AppPlatformNameEnum {
  APPLE = 'apple',
  GOOGLE = 'google',
  AMAZON = 'amazon',
  XIAOMI = 'xiaomi',
}

export interface AppDTO {
  type: QRCodeTypeEnum.APP;
  name: string;
  colors: ColorsDTO;
  appInfo: AppInfoDto;
  platformLinks: PlatformLinkDto[];
  loaderImage: CloudinaryMediaDetailsDTO | null;
}

export interface TextDTO {
  type: QRCodeTypeEnum.PLAIN_TEXT;
  name: string;
  text: string;
}

export type ScheduleDayDateRangeDTO = {
  start: Date | string | number;
  end: Date | string | number;
};

export type ScheduleDayDTO = {
  isActive: boolean;
  dateRanges: ScheduleDayDateRangeDTO[];
};

export type ScheduleDTO = {
  isAm: boolean;
  monday: ScheduleDayDTO;
  tuesday: ScheduleDayDTO;
  wednesday: ScheduleDayDTO;
  thursday: ScheduleDayDTO;
  friday: ScheduleDayDTO;
  saturday: ScheduleDayDTO;
  sunday: ScheduleDayDTO;
};

export interface BusinessPageDTO {
  type: QRCodeTypeEnum.BUSINESS_PAGE;
  name: string;
  colors: ColorsDTO;
  image: CloudinaryMediaDetailsDTO | null;
  company: {
    name: string;
    tagline: string;
    summary: string;
  };
  buttons: ButtonDTO[];
  schedule: ScheduleDTO;
  address: AddressDTO;
  facilities: string[];
  contactDetails: {
    name: string;
    surname: string;
  };
  contacts: ContactsDTO;
  summary: string;
  links: LinkDTO[];
  loaderImage: CloudinaryMediaDetailsDTO | null;
}

// TODO MOVE THESE TYPES TO type folder
export type TemplateDTO =
  | VCardDTO
  | WebsiteDTO
  | AppDTO
  | TextDTO
  | BusinessPageDTO
  | VideoDTO
  | WiFiDTO
  | PdfDTO
  | ImageGalleryDTO
  | MenuDTO
  | FacebookDTO
  | SocialMediaDTO;

export type TemplateFactory = (entry?: RecursivePartial<TemplateDTO>, medias?: MediaDTO[]) => TemplateDTO;

export interface UserSettingsDTO {
  hideFooter?: boolean;
}

export interface MalwareStatsJson {
  lastScannedAt?: string;
  containsMalware?: boolean;
  lastScannedService?: string;
  webriskLastScannedAt?: string;
}

export interface MediaDTO {
  publicId: string;
  transformationStatus: TransformationStatusEnum;
  type: string;
  storageProvider: string;
  createdAt: string;
  updatedAt: string;
  validationStatus: string | null;
}

export interface QRCodeDTO {
  id: string;
  userLanguage: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  media: MediaDTO[];
  uri: string;
  content: TemplateDTO | null;
  qrDesign: QRCodeOptionsDTO;
  scansAmount: number;
  disabled: boolean;
  autoDisabled: boolean;
  userSettingsJson: UserSettingsDTO;
  malwareStatsJson?: MalwareStatsJson;
}

export * from './apiCallTypes';

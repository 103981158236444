import { RecursivePartial } from 'types';
import { QRCodeTypeEnum } from 'types/qrCodes';
import createCloudinaryMediaDetailsDTO from 'api/media/entities/createCloudinaryMediaDetailsDTO';
import { VCardDTO, MediaDTO } from '../types';
import { createLinkDTO } from './createLinkDTO';
import { createColorsDTO } from './createColorsDTO';
import { createAddressDTO } from './createAddressDTO';
import { createContactsDTO } from './createContactsDTO';

export const createVCardDTO = (entry?: RecursivePartial<VCardDTO>, medias?: MediaDTO[]): VCardDTO => {
  return {
    type: QRCodeTypeEnum.V_CARD,
    name: entry?.name || '',
    colors: createColorsDTO(entry?.colors),
    profile: {
      name: entry?.profile?.name || '',
      surname: entry?.profile?.surname || '',
      image: createCloudinaryMediaDetailsDTO(entry?.profile?.image, medias),
    },
    contacts: createContactsDTO(entry?.contacts),
    company: {
      name: entry?.company?.name || '',
      position: entry?.company?.position || '',
    },
    summary: entry?.summary || '',
    address: createAddressDTO(entry?.address),
    links: (entry?.links || []).map((linkEntry) => createLinkDTO(linkEntry)),
    loaderImage: createCloudinaryMediaDetailsDTO(entry?.loaderImage, medias),
  };
};

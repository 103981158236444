import { t } from 'i18next';

import { QRCodeDTO, VCardDTO } from 'api/qrCode/types';
import { QRCodeTypeEnum } from 'types/qrCodes';
import { VideoSourceDto } from 'api/qrCode/types/VideoDTO';
import { WiFiDTO } from 'api/qrCode/types/WiFiDTO';
import { BASE_MOBI_URL } from 'constants/api';
import { ENV } from 'constants/constants';
import { COLORS } from 'styles';
import fileUploadApi from 'api/file-upload';

const isDev = ENV === 'development';

class QrCodeService {
  public generateQRCodeScanLink(uri: QRCodeDTO['uri'], locale?: string): string {
    return `${process.env.NEXT_PUBLIC_HOST}${locale ? `/${locale}` : ''}/qr/${uri}`;
  }

  public generateQRCodeDownloadLink(uri: QRCodeDTO['uri'], locale?: string): string {
    return `${process.env.NEXT_PUBLIC_HOST}${locale ? `/${locale}` : ''}/qr-download/${uri}`;
  }

  public generateRedirectQRCodeScanLink(uri: QRCodeDTO['uri']): string {
    return `${BASE_MOBI_URL}/${uri}`;
  }

  public generateRedirectQRCodeDownloadLink(uri: QRCodeDTO['uri']): string {
    return `${BASE_MOBI_URL}/${uri}`;
  }

  public generateWiFiScanLink(content: WiFiDTO): string {
    return `WIFI:T:${content.encryption};S:${content.ssid};P:${content.password};H:${content.hidden || ''};;`;
  }

  public prepareMobiPlainQrCodeToScan(qrCode: QRCodeDTO): QRCodeDTO | undefined {
    return {
      ...qrCode,
      name: qrCode.name || t('qrBuilder.templateForms.shared.sections.codeName.untitled', 'Untitled'),
      qrDesign: { ...qrCode.qrDesign, data: BASE_MOBI_URL },
    };
  }

  public prepareWebsitePlainQrCodeToScan(qrCode: QRCodeDTO): QRCodeDTO | undefined {
    if (qrCode.content?.type === QRCodeTypeEnum.URL) {
      return {
        ...qrCode,
        name: qrCode.name || t('qrBuilder.templateForms.shared.sections.codeName.untitled', 'Untitled'),
        qrDesign: { ...qrCode.qrDesign, data: qrCode.content?.url },
      };
    }
    return undefined;
  }

  private generateVCardQRContent(data: VCardDTO) {
    let vCard = `BEGIN:VCARD\nVERSION:3.0\n`;
    if (data.profile.name) {
      vCard += `N:${data.profile.name};${data.profile.surname};;;\n`;
      vCard += `FN:${data.profile.name} ${data.profile.surname}\n`;
    }
    if (data.contacts.phoneNumber) {
      vCard += `TEL;TYPE=CELL:${data.contacts.phoneNumber}\n`;
    }
    if (data.contacts.alternativePhoneNumber) {
      vCard += `TEL;TYPE=WORK:${data.contacts.alternativePhoneNumber}\n`;
    }
    if (data.contacts.email) {
      vCard += `EMAIL;TYPE=PREF,INTERNET:${data.contacts.email}\n`;
    }
    if (data.contacts.website) {
      vCard += `URL:${data.contacts.website}\n`;
    }
    if (data.company.name) {
      vCard += `ORG:${data.company.name}\n`;
    }
    if (data.company.position) {
      vCard += `TITLE:${data.company.position}\n`;
    }
    if (data.summary) {
      vCard += `NOTE:${data.summary}\n`;
    }
    if (data.address.country) {
      vCard += `ADR;TYPE=HOME:;;${data.address.street};${data.address.city};${data.address.state};${data.address.postalCode};${data.address.country}\n`;
    }
    vCard += `END:VCARD`;
    return vCard;
  }

  public prepareVCardPlainQrCodeToScan(qrCode: QRCodeDTO): QRCodeDTO | undefined {
    if (qrCode.content?.type === QRCodeTypeEnum.V_CARD) {
      return {
        ...qrCode,
        name: qrCode.name || t('qrBuilder.templateForms.shared.sections.codeName.untitled', 'Untitled'),
        qrDesign: { ...qrCode.qrDesign, data: this.generateVCardQRContent(qrCode.content) },
      };
    }
    return undefined;
  }

  private prepareGenericQrCodeToScan(
    qrCode: QRCodeDTO,
    linkGenerator: typeof this.generateQRCodeScanLink,
    locale?: string
  ): QRCodeDTO {
    if (qrCode.content?.type === QRCodeTypeEnum.WIFI) {
      return {
        ...qrCode,
        name: qrCode.name || t('qrBuilder.templateForms.shared.sections.codeName.untitled', 'Untitled'),
        qrDesign: { ...qrCode.qrDesign, data: this.generateWiFiScanLink(qrCode.content) },
      };
    }
    return {
      ...qrCode,
      name: qrCode.name || t('qrBuilder.templateForms.shared.sections.codeName.untitled', 'Untitled'),
      qrDesign: { ...qrCode.qrDesign, data: linkGenerator(qrCode.uri, locale) },
    };
  }

  public prepareQRCodeToScan(qrCode: QRCodeDTO, locale?: string): QRCodeDTO {
    return this.prepareGenericQrCodeToScan(
      qrCode,
      isDev ? this.generateQRCodeScanLink : this.generateRedirectQRCodeScanLink,
      locale
    );
  }

  public prepareCustomizedQRCodeToScan(qrCode: QRCodeDTO, locale?: string): QRCodeDTO {
    const qrCodeData = this.prepareQRCodeToScan(qrCode, locale);

    return {
      ...qrCodeData,
      qrDesign: {
        ...qrCodeData.qrDesign,
        frame: { ...qrCodeData.qrDesign.frame, type: 'default' },
        image: {
          selected: null,
          uploaded: null,
        },
        backgroundOptions: {
          color: COLORS.WHITE,
        },
        dotsOptions: {
          ...qrCodeData.qrDesign.dotsOptions,
          color: COLORS.ILLUSTRATED_QRCODE,
          type: 'square',
        },
        cornersSquareOptions: {
          ...qrCodeData.qrDesign.cornersSquareOptions,
          color: COLORS.ILLUSTRATED_QRCODE,
          type: 'square',
        },
        cornersDotOptions: {
          ...qrCodeData.qrDesign.cornersDotOptions,
          color: COLORS.ILLUSTRATED_QRCODE,
          type: 'square',
        },
      },
    };
  }

  public prepareQRCodeToDownload(qrCode: QRCodeDTO, locale?: string): QRCodeDTO {
    return this.prepareGenericQrCodeToScan(
      qrCode,
      isDev ? this.generateQRCodeDownloadLink : this.generateRedirectQRCodeDownloadLink,
      locale
    );
  }

  public getLinksForPlayer(media: VideoSourceDto): string {
    if (media && media?.uploaded?.publicId) {
      return fileUploadApi.buildResourceURLForPlayer(media.uploaded);
    }

    return (media && media?.url) || '';
  }
}

export const qrCodeService = new QrCodeService();

import { RecursivePartial } from 'types';
import { CloudinaryMediaDetailsDTO } from '../types';
import { MediaDTO } from 'api/qrCode/types';

const createCloudinaryMediaDetailsDTO = (
  entry?: RecursivePartial<CloudinaryMediaDetailsDTO> | null,
  medias?: MediaDTO[]
): CloudinaryMediaDetailsDTO => {
  const media = medias?.find((media) => media.publicId === entry?.publicId);
  const result: CloudinaryMediaDetailsDTO = {
    publicId: entry?.publicId || '',
    resourceType: entry?.resourceType || '',
    format: entry?.format || '',
    bytes: entry?.bytes || 0,
    ...(entry?.key && { key: entry.key }),
    ...(entry?.storageProvider && { storageProvider: entry.storageProvider }),
    ...(entry?.bucketRootUrl && { bucketRootUrl: entry.bucketRootUrl }),
    ...(media?.transformationStatus && { hasAutoTransformation: media.transformationStatus }),
  };

  return result;
};

export default createCloudinaryMediaDetailsDTO;

/* eslint-disable consistent-return */
/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { showNotification } from 'config/toast';
import { getValidErrorMessage } from 'utils';
import { userSettingsApi } from '../../api/userSettings';
import { I18N_LANGUAGE_TO_STRIPE_LOCALE } from 'constants/languages';
import { RootState } from 'store';

export const fetchCountries = createAsyncThunk('account/fetchCountries', async (language: string, { getState }) => {
  try {
    const state = getState() as RootState;
    if (state.account.countriesFetched === language) {
      return state.account.countries;
    }
    return await userSettingsApi.getCountries(language);
  } catch (error: any) {
    if (error?.request?.status !== 401) {
      showNotification(getValidErrorMessage(error), 'error');
    }
    return [];
  }
});

export const fetchAccount = createAsyncThunk('account/fetchAccount', async () => {
  try {
    return userSettingsApi.getSubscriptionProduct();
  } catch (error: any) {
    console.error(error);
    showNotification(getValidErrorMessage(error), 'error');
    return error;
  }
});

export const fetchLanguages = createAsyncThunk(
  'account/fetchLanguages',
  async (params: { language: keyof typeof I18N_LANGUAGE_TO_STRIPE_LOCALE }) => {
    try {
      return userSettingsApi.getLanguages(params);
    } catch (error: any) {
      console.error(error);
      showNotification(getValidErrorMessage(error), 'error');
      return error;
    }
  }
);

export const fetchNativeLanguages = createAsyncThunk('account/fetchNativeLanguages', async () => {
  try {
    return userSettingsApi.getNativeLanguages();
  } catch (error: any) {
    console.error(error);
    showNotification(getValidErrorMessage(error), 'error');
    return error;
  }
});

export const fetchTimezones = createAsyncThunk(
  'account/fetchTimezones',
  async (params: { language: keyof typeof I18N_LANGUAGE_TO_STRIPE_LOCALE }) => {
    try {
      return userSettingsApi.getTimeZones(params);
    } catch (error: any) {
      console.error(error);
      showNotification(getValidErrorMessage(error), 'error');
      return error;
    }
  }
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QRCodeDTO } from 'api/qrCode/types';
import { qrCodeService } from 'libs/core/qr-codes/service/qr-codes.service';
import { fetchQRCodes } from './thunks';
import { logout } from '../auth/thunks';

interface QrCodesState {
  qrCodes: QRCodeDTO[];
  loaded: boolean;
}

// Define the initial state using that type
const initialState: QrCodesState = {
  qrCodes: [],
  loaded: false,
};

export const qrCodesSlice = createSlice({
  name: 'qrCodes',
  initialState,
  reducers: {
    setQRCodes: (state, action: PayloadAction<QRCodeDTO[]>) => {
      state.qrCodes = action.payload.map((qrCode) => qrCodeService.prepareQRCodeToScan(qrCode));
      state.loaded = true;
    },
  },
  extraReducers: (qrCodes) => {
    qrCodes.addCase(fetchQRCodes.fulfilled, (state, action) => {
      state.qrCodes = action.payload;
      state.loaded = true;
    });
    qrCodes.addCase(logout.fulfilled, (state) => {
      state.qrCodes = [];
      state.loaded = false;
    });
    qrCodes.addCase(fetchQRCodes.pending, (state) => {
      state.loaded = false;
    });
  },
});

export const { setQRCodes } = qrCodesSlice.actions;

export default qrCodesSlice.reducer;

import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { BASE_URL } from 'constants/api';
import { ALLOWED_LANGUAGES, DEFAULT_LOCALE } from 'constants/languages';
import { addYears } from 'date-fns';

const CACHE_TRANSLATIONS_TTL_SEC = process.env.CACHE_TRANSLATIONS_TTL_SEC
  ? +process.env.CACHE_TRANSLATIONS_TTL_SEC
  : 60;

const getLang = () => {
  if (typeof window !== 'undefined') {
    const pathname = window.location.pathname;
    const locale = ALLOWED_LANGUAGES.find((locale) => {
      return new RegExp(`/${locale}(/|$)`).test(pathname);
    });
    // LINKS.QR_GENERATOR, LINKS.FLOW_LANDING_V4
    if (['/qr-generator', '/flow4'].includes(`/${pathname.split('/').pop()}`)) return 'en';

    return locale || DEFAULT_LOCALE;
  }

  return undefined;
};

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    initImmediate: false,
    preload: false,
    debug: false,
    lng: getLang(),

    fallbackLng: false,
    nonExplicitSupportedLngs: false,
    keySeparator: '.',
    interpolation: {
      escapeValue: false, // react already safes from xss
      skipOnVariables: true,
      nestingOptionsSeparator: ';',
    },
    backend: {
      expirationTime: CACHE_TRANSLATIONS_TTL_SEC * 1000,
      reloadInterval: CACHE_TRANSLATIONS_TTL_SEC * 1000,
      loadPath: ([lang]: string[]) => {
        return `${BASE_URL}/api-v1/common/translations/${lang}`;
      },
      requestOptions: {
        cache: 'no-cache',
      },
    },
    load: 'languageOnly',
    // supportedLngs: SUPPORTED_LANGUAGES,
  } as InitOptions);

export default i18n;

export const changeLanguage = async (lng: string) => {
  const expiresFormatted = addYears(new Date(), 1).toUTCString();

  document.cookie = `lang=${lng ?? DEFAULT_LOCALE}; expires=${expiresFormatted}; domain=${
    window.location.hostname
  }; path=/`;

  return i18n.changeLanguage(lng);
};

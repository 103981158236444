/* eslint-disable class-methods-use-this */
import { ROUTES } from 'constants/api.routes';
import {
  SubscriptionProduct,
  CreateSubscriptionRequestParams,
  CreatePayPalSubscriptionResponse,
  CreateStripeSubscriptionResponse,
  Subscription,
} from 'types/subscriptions';
import { BillingTransaction, PaymentDetailsResponse } from 'types/billing';
import { DefaultApiResponse } from 'types';
import { axiosApiInstance } from '../axios';
import { AxiosRequestConfig } from 'axios';

class SubscriptionApi {
  public async getSubscriptionProduct(currencyCode?: string, experimental = false): Promise<SubscriptionProduct[]> {
    const request: AxiosRequestConfig = {
      headers: {
        'x-experimental': String(experimental),
      },
    } as AxiosRequestConfig;

    if (currencyCode && request.headers) {
      request.headers['x-currency'] = currencyCode;
    }

    const { data } = await axiosApiInstance.get(ROUTES.SUBSCRIPTION.PRODUCTS, request);

    return data;
  }

  public async getSubscriptionProductsForUser(
    currencyCode?: string,
    experimental = false,
    shoulShowNotification = true
  ): Promise<SubscriptionProduct[]> {
    const request: AxiosRequestConfig = {
      shouldShowErrorNotification: shoulShowNotification,
      headers: {
        'x-experimental': String(experimental),
      },
    } as AxiosRequestConfig;

    if (currencyCode && request.headers) {
      request.headers['x-currency'] = currencyCode;
    }

    const fromPage = window.location.pathname.split('/').pop();
    const { data } = await axiosApiInstance.get(`${ROUTES.SUBSCRIPTION.USER_PRODUCTS}?fromPage=${fromPage}`, request);

    return data;
  }

  public async getCurrencies(): Promise<SubscriptionProduct[]> {
    const { data } = await axiosApiInstance.get(ROUTES.SUBSCRIPTION.CURRENCIES, {
      // disable browser cache
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });

    return data;
  }

  public async createStripeSubscription(
    payload: CreateSubscriptionRequestParams
  ): Promise<CreateStripeSubscriptionResponse> {
    const { data } = await axiosApiInstance.post(ROUTES.SUBSCRIPTION.CREATE_STRIPE, payload);

    return data;
  }

  public async createPayPalSubscription(
    payload: Omit<CreateSubscriptionRequestParams, 'paymentMethodId'>
  ): Promise<CreatePayPalSubscriptionResponse> {
    const { data } = await axiosApiInstance.post(ROUTES.SUBSCRIPTION.CREATE_PAYPAL, payload);

    return data;
  }

  public async createPayPalIncompleteSubscription(
    payload: Omit<CreateSubscriptionRequestParams, 'paymentMethodId'>
  ): Promise<CreatePayPalSubscriptionResponse> {
    const { data } = await axiosApiInstance.post(ROUTES.SUBSCRIPTION.CREATE_PAYPAL_INITIAL, payload);

    return data;
  }

  public async getCurrentSubscription(): Promise<Subscription> {
    const { data } = await axiosApiInstance.get(ROUTES.SUBSCRIPTION.CURRENT);

    return data;
  }

  public async getTransactions(): Promise<BillingTransaction[]> {
    const { data } = await axiosApiInstance.get(ROUTES.BILLING.TRANSACTIONS);

    return data;
  }

  public async cancelSubscription(subscriptionId: string): Promise<DefaultApiResponse> {
    const { data } = await axiosApiInstance.post(ROUTES.SUBSCRIPTION.CANCEL(subscriptionId));

    return data;
  }

  public async reactivateSubscription(subscriptionId: string): Promise<DefaultApiResponse> {
    const { data } = await axiosApiInstance.post(ROUTES.SUBSCRIPTION.REACTIVATE(subscriptionId));

    return data;
  }

  public async initiatePaymentForCurrentSubscription(): Promise<DefaultApiResponse> {
    const { data } = await axiosApiInstance.post(ROUTES.BILLING.BILLING_PAY);

    return data;
  }

  public async changeCard(paymentMethodId: string): Promise<DefaultApiResponse> {
    const { data } = await axiosApiInstance.post(ROUTES.BILLING.CHANGE_CARD, { paymentMethodId });

    return data;
  }

  public async paymentDetails(): Promise<PaymentDetailsResponse> {
    const { data } = await axiosApiInstance.get(ROUTES.BILLING.PAYMENT_DETAILS);

    return data;
  }

  public async getTransactionInvoice(transactionId: string): Promise<string> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { data } = await axiosApiInstance.get(ROUTES.BILLING.INVOICE(transactionId), {
      params: { timezone },
      responseType: 'blob',
    });

    return data;
  }

  public async cancelPublicSubscriptionRequest(email: string): Promise<DefaultApiResponse> {
    const { data } = await axiosApiInstance.post(ROUTES.SUBSCRIPTION.COMMON.CANCEL_REQUEST, { email });

    return data;
  }

  public async cancelPublicSubscription(subscriptionId: string): Promise<DefaultApiResponse> {
    const { data } = await axiosApiInstance.post(ROUTES.SUBSCRIPTION.COMMON.CANCEL(subscriptionId));

    return data;
  }
}

export const subscriptionApi = new SubscriptionApi();
